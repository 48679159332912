import type { AppProps } from 'next/app'
import {NextComponentType, NextPageContext} from "next";
import '../routes'
// import 'core/assets/scss/index.scss'
import 'src/styles/tailwind.css'
import 'eyecons-components/dist/index.css'

type CustomComponentType = NextComponentType<NextPageContext, any, any> & {
    getLayout?(page: React.ReactNode): React.ReactNode
}

export default function MyApp({ Component, pageProps }: AppProps) {
    // Use the layout defined at the page level, if available
    const getLayout = (Component as CustomComponentType).getLayout || ((page) => page)

    return getLayout(<Component {...pageProps} />)
}
import {Router} from 'ra-fetch'


const auth = Router.api('auth', process.env.NEXT_PUBLIC_STORYBOOK_SERVICE_AUTH_URL)
    .laravel()
    .bearTokenFromCookie('token')
    .loginURL('/login')
    .logoutURL('/logout')
    .csrfURL('/sanctum/csrf-cookie')

    .index('users', '/api/users')
    .show('users', '/api/users/{id}')
    .show('users.exists', '/api/users/{email}/exists')
    .store('users', '/api/users')

    .store('users.token', '/token')
    .update('users', '/api/users/{id}')
    .delete('users', '/api/users/{id}')
    .delete('users.channels', '/api/users/{id}/channels/{channel_id}')

    .show('user', '/api/user')
    .update('user', '/api/user')

    .store('password.forgot', '/forgot-password')
    .store('password.reset', '/reset-password')
    .store('password.update', '/update-password')
    .store('email.verify', '/email/verify')
    .store('register', '/register')

    .index('tokens', '/api/user/tokens')
    .store('tokens', '/api/user/tokens')
    .delete('tokens', '/api/user/tokens/{id}')

    .index('permissions', '/api/permissions')

    .index('roles', '/api/roles')
    .show('roles', '/api/roles/{id}')
    .update('roles', '/api/roles/{id}')

    .store('validations', '/api/validations')

const ecommerce = Router.api('ecommerce', process.env.NEXT_PUBLIC_STORYBOOK_SERVICE_ECOMMERCE_URL)
    .laravel()
    .bearTokenFromCookie('token')

    // Billing Routes
    .index('billings', '/billings')
    .update('billings.order', '/billings/{id}/order')

    // Order Routes
    .index('orders', '/orders')
    .store('orders', '/orders')
    .show('orders', '/orders/{id}')

    // Customer Routes
    .show('customers.owns.video', '/customers/{id}/owns/{external_id}')
    .show('customers.paid.video', '/customers/{id}/paid/{orderable_id}')

    // Plan Routes
    .index('plans', '/plans')
    .show('plans', '/plans/{id}')
    .store('plans', '/plans', {form_data: true})
    .update('plans', '/plans/{id}', {form_data: true})
    .delete('plans', '/plans/{id}')
    .store('plans.videos.store', '/plans/{id}/video/{video_id}')
    .delete('plans.videos.delete', '/plans/{id}/video/{video_id}')

    // Subscription Routes
    .index('subscriptions', '/subscriptions')
    .show('subscriptions', '/subscriptions/{id}')
    .store('subscriptions', '/subscriptions')
    .update('subscriptions.cancel', '/subscriptions/{id}/cancel')

    // Redeem Routes
    .store('redeems', '/redeems')

    // Voucher Routes
    .index('vouchers', '/vouchers')
    .show('vouchers', '/vouchers/{id}')
    .store('vouchers', '/vouchers')
    .store('vouchers.validate', '/vouchers/{hash}/validate')
    .update('vouchers', '/vouchers/{id}')
    .delete('vouchers', '/vouchers/{id}')
    .store('vouchers.emails', '/vouchers/{id}/emails')


const backend = Router.api('backend', process.env.NEXT_PUBLIC_STORYBOOK_SERVICE_BACKEND_URL)
    .laravel()
    .bearTokenFromCookie('token')

    // Analytics Routes
    .index('analytics', '/analytics')

    // Playlist Routes
    .index('public.playlists', '/public/playlists')
    .index('playlists', '/playlists')
    .show('public.playlists', '/public/playlists/{id}')
    .show('playlists', '/playlists/{id}')

    // Category Routes
    .index('categories', '/categories')

    //Types
    .index('types', '/types')

    // Channel Routes
    .index('public.channels', '/public/channels')
    .index('channels', '/channels')
    .index('public.channels.homepage', '/public/channels/homepage')
    .show('public.channels', '/public/channels/{id}')
    .show('channels', '/channels/{id}')

    //Notifications Routes
    .index('notifications', '/notifications')
    .store('notifications', '/notifications')
    .delete('notifications', '/notifications/{id}')

    //Channel Teams Routes
    .index('channel_teams', '/channel_teams')

    // UserChannel Routes
    .index('user_channels', '/user_channels')
    .store('user_channels', '/user_channels')
    .store('user_channels_bulk', '/user_channels/bulk')
    .delete('user_channels', '/user_channels/{id}')

    // Channel Tag Routes
    .index('channels.tag.update', '/channels/{id}/video/{video_id}')

    // Livestream Routes
    .index('livestreams', '/livestreams')
    .show('livestreams', '/livestreams/{id}')

    // Ecapture routes
    .index('emails', '/emails')
    .index('emails.export', '/emails/export')
    .show('emails', '/emails/{id}')

    // Team Routes
    .index('tags', '/tags')
    .show('tags', '/tags/{id}')
    .store('tags', '/tags')

    // Team Routes
    .index('teams', '/teams')
    .show('teams', '/teams/{id}')

    // User Routes
    .show('user.channel', '/channels/{id}/user')
    .index('user.channels', '/user/{id}/channels')
    .index('users.channels', '/users/{id}/channels')

    // Video Routes
    .index('public.videos', '/public/videos')
    .index('videos', '/videos')
    .index('public.videos.trending', '/public/videos/trending')
    .show('public.videos', '/public/videos/{id}')
    .show('videos', '/videos/{id}')
    .show('videos.private', '/videos/{hash}/private')
    .show('videos.paid', '/videos/{id}/paid')

    // Sport Routes
    .index('public.sports', '/public/sports')
    .index('sports', '/sports')

const media = Router.api('media', process.env.NEXT_PUBLIC_STORYBOOK_SERVICE_MEDIA_URL)
    .laravel()
    .store('clips', `/videos/{id}/clip`)

    .show('media', '/media/{id}')

    .store('messages', '/messages')
    .store('messages.subscribe', '/messages/subscribe')
    .store('messages.unsubscribe', '/messages/unsubscribe')
    .show('messages.subscribed', '/messages/{topic}/subscribed/{token}')

    .show('subscribers.count', '/subscribers/count')

    .index('comments', '/comments')
    .store('comments', '/comments')
    .delete('comments', '/comments/{id}')

    .show('likes.count', '/likes/count')
    .store('likes', '/likes')

    .index('video_cards', '/video_cards')

    .index('livestreams', '/livestreams')
    .show('livestreams', '/livestreams/{id}')

    .index('events', '/events')
    .index('public.events', '/public/events')
    .show('public.events.count', '/public/events/count')
    .show('events', `/events/{id}`)

    .index('videos', `/videos`)
    .show('videos', `/videos/{id}`)

    // VideoChapter Routes
    .index('video_chapters', '/video_chapters')
    .show('video_chapters', '/video_chapters/{id}')

if (process.env.NEXT_PUBLIC_STORYBOOK_USER_TOKEN) {
    Router.bearerToken(process.env.NEXT_PUBLIC_STORYBOOK_USER_TOKEN)
    auth.bearerToken(process.env.NEXT_PUBLIC_STORYBOOK_USER_TOKEN)
    backend.bearerToken(process.env.NEXT_PUBLIC_STORYBOOK_USER_TOKEN)
    ecommerce.bearerToken(process.env.NEXT_PUBLIC_STORYBOOK_USER_TOKEN)
    media.bearerToken(process.env.NEXT_PUBLIC_STORYBOOK_USER_TOKEN)
}
